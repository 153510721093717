import backendAPI from "@/api/backend-api";
import {isNull} from "lodash";
import api from "@/api/message-api";

const state = {
    leads: [],
    loading: false,
    hideProcessed: false,
    hideSent: false,
    noErrors: false,
    zoneOnly: false,
    usOnly: false,
    brandedOnly: false,
    dropDate: "",

};

const mutations = {
    SET_LOADING(state, payload) {
        state.loading = payload;
    },
    SET_LEADS(state, data) {
        state.leads = data;
    },


    UPDATE_LEADS(state, payload) {
        for (let lead of payload) {
            const updatedItem = state.leads.find(item => item.id === lead.id)
            if(updatedItem){
                updatedItem.approvedToSend = lead.approvedToSend
                updatedItem.reviewDate = lead.reviewDate
            }

        }
    },

    SET_HIDE_PROCESSED(state, payload) {
        state.hideProcessed = payload;
    },
    SET_HIDE_SENT(state, payload) {
        state.hideSent = payload;
    },

    SET_ZONE_ONLY(state, payload) {
        state.zoneOnly = payload;
    },

    SET_US_ONLY(state, payload) {
        state.usOnly = payload;
    },

    SET_NO_ERRORS(state, payload) {
        state.noErrors = payload;
    },

    SET_BRANDED_ONLY(state, payload) {
        state.brandedOnly = payload;
    },

};

const actions = {

    async search({commit, dispatch}, filter) {
        commit("SET_LOADING", true);
        console.log("Searching for ", filter);

        const response =  await backendAPI.searchLeads(filter).catch(e => {
            commit("SET_LOADING", false);
            if ('Unauthorized' === e.response.data.error) {
                dispatch("logout", null, {root: true});
            }
        });
        if (response.data.length !== 0) {
            commit("SET_LEADS", response.data);
        }
        commit("SET_LOADING", false);
    },
    async spamReports({commit}, payload) {
        commit("SET_LOADING", true);
        let response = await backendAPI.spamReports(payload);
        commit("SET_LEADS", response.data);
        commit("SET_ACTIVE_DATE", payload.dropDate)
        commit("SET_LOADING", false);
    },
    async bounces({commit}, payload) {
        commit("SET_LOADING", true);
        let response = await backendAPI.bounces(payload);
        commit("SET_LEADS", response.data);
        commit("SET_ACTIVE_DATE", payload.dropDate)
        commit("SET_LOADING", false);
    },


    async updateLeadSending({dispatch, state, commit}, payload) {
        commit("SET_LOADING", true);

        let response = await backendAPI.updateLeadSending(payload);
        let leads = response.data;
        commit("UPDATE_LEADS", leads);
        commit("SET_LOADING", false);
    },
};

const getters = {

    getSortedLeads: (state) => {
        let filtered = state.hideProcessed ? state.leads.filter(lead => isNull(lead.reviewDate))
            : state.leads;
        filtered = state.hideSent ? filtered.filter(lead => lead.sentDate===null) : filtered;
        filtered = state.noErrors ? filtered.filter(lead => lead.error==="Needs reviewing"
                // || lead.error==="Not approved particle"
            ) : filtered;

        filtered = state.zoneOnly ? filtered.filter(lead => lead.type && lead.type.startsWith('zone')) : filtered;
        filtered = state.usOnly ? filtered.filter(lead => lead.country && (
            lead.country === 'US' || lead.country === "United States" || lead.country === "Canada" || lead.country === "CA")
        ) : filtered;
        filtered = state.brandedOnly ? filtered.filter(lead => lead.brandedEmail===true) : filtered;

        return filtered.sort((a, b) => { a.domain && b.domain && a.domain.localeCompare(b.domain); }
        )
    },
};
const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
};
