import conversationAPI from "@/api/conversation-api";
import messageAPI from "@/api/message-api";
import callAPI from "@/api/call-api";
import moment from "moment";
import backendApi from "../../api/backend-api";
import _ from 'lodash'
const state = {
    conversations: [],
    inbox: [],
    sortDrop: false,
    activeConversationId: -1,
    loading: false,
    conversationStages: [
        "all",
        "initial",
        "not interested",
        "interested",
        "aware",
        "negotiating",
        "backorder",
        "ordered",
        "pending sale",
        "reminded",
        "redeemed",
        "auction",
        "pass",
        "sold",
        "missed",
        "dropped",
    ],
};

const mutations = {


    SET_ACTIVE_CONVERSATION_ID(state, payload) {
        console.log("Setting active conversation id", payload)
        state.activeConversationId = payload;
    },
    SET_CONVERSATIONS(state, data) {
        state.conversations = data.conversations;
        state.totalItems = data.totalElements;
    },
    ADD_CONVERSATIONS(state, data) {
        const targetConversation = state.conversations
            .concat(data.conversations)
            .reduce((res, val) => {
                res[val.id] = val
                return res
            }, {})

        state.conversations = Object.values(targetConversation)
    },

    UPDATE_TOTAL_PAGES(state, totalPages) {
        state.totalPages = totalPages
    },

    ADD_MESSAGE_TO_INBOX(state, message) {
        const existing = state.conversations ?
            state.conversations
                .flatMap((con) => con.messages)
                .find((m) => (m.id === message.id))
            : undefined;

        if (existing === undefined) {
            state.inbox.push(message);
        }
    },

    SET_LOADING(state, payload) {
        state.loading = payload;
    },

    CLEAR_INBOX(state) {
        state.inbox = [];
    },

    DELETE_CONVERSATION(state, id) {
        state.conversations = state.conversations.filter((c) => c.id !== id);
    },

    UPDATE_CONVERSATION_DOMAIN(state, update) {
        const conversation = state.conversations.find((c) => c.id === update.id);
        if (conversation !== undefined) {
            conversation.lead.domain = update.domain;
        } else {
            console.log("Did not find conversation id: ", update.id);
        }
    },

    UPDATE_STAGE_BY_NAME(state, update) {
        state.conversations
            .filter((c) => c.domain === update.domainName)
            .map((c) => (c.stage = update.stage));
    },

    UPDATE_TEXT(state, payload) {
        state.conversations
            .filter((c) => c.id === payload.id)
            .map((c) => (c.inputText = payload.inputText));
    },

    ADD_MESSAGE(state, message) {

        console.log("Adding message to existing conversation ...", message)
        const conversation = state.conversations.find(
            (c) => c.id === message.conversationId
        );

        if (conversation !== undefined) {
            if (conversation.messages === null) {
                conversation.messages = [];
            }
            const existing = conversation.messages.find((it) => it.id === message.id);
            if (existing) {
                console.log(
                    "Message is already present in conversation: ",
                    conversation,
                    "message:",
                    message,
                    "existing:",
                    existing
                );
            } else {
                console.log(
                    "Adding to conversation: ",
                    conversation,
                    "message: ",
                    message
                );
                conversation.messages.push(message);
                conversation.notAnswered = false;
                if (!message.user) {
                    conversation.lastUpdated = message.dateCreated;
                }
                conversation.stage = message.conversationStage;
            }
        } else {
            console.log("Did not find conversation for: ", message);
            state.inbox.push(message);
        }
    },


    UPDATE_CONVERSATION_STATE_BY_ID(state, payload) {
        state.conversations
            .filter((c) => c.id === payload.id)
            .map((c) => (c.stage = payload.stage));
    },



    UPDATE_MESSAGE(state, payload) {

        const updateIndex = state.conversations.findIndex(
            (item) => item.messages.find(message => message.id === payload.id)
        );

        if (updateIndex === -1) {
            console.log("Message not found in any conversation");
            return;
        }

        const conversation = state.conversations[updateIndex];

        const messageIndex = conversation.messages.findIndex(
            (it) => it.id === payload.id
        );
        if (messageIndex !== undefined) {
            conversation.messages = [
                ...conversation.messages.slice(0, messageIndex),
                payload,
                ...conversation.messages.slice(messageIndex + 1),
            ];
        }


    },

    ADD_CONVERSATION(state, conversation) {
        state.conversations = [
            ...state.conversations.filter(conversations => conversations.id !== conversation.id),
            conversation
        ]
        console.log("Added conversation ...", conversation)
    },
};

const actions = {
    async newConversationAction({commit, getters, dispatch}, conversation) {

        try {
            const {data} = await conversationAPI.add(conversation);
            console.log("Received Conversation: ", data);
            commit("ADD_CONVERSATION", data);

        } catch (error) {
            console.log(error.response.data);

            let snack = {
                data: {
                    text: error.response.data.message
                },
                color: "red",
                timeout: 5000,
            };
            dispatch("snackbar/setSnackbar", snack, {root: true});

        }
    },


    processInbox({commit, state, dispatch}) {

        state.inbox.forEach((message) => {
            console.log("Processing message ...", message)
            const conversation = state.conversations.find(
                (c) => c.id === message.conversationId
            );

            if (conversation !== undefined) {
                console.log("Found conversation ...", conversation)
                commit("ADD_MESSAGE", message);
            } else {
                dispatch("messages/addMessageAction", message, {root: true});
            }
        });
        commit("CLEAR_INBOX");
    },

    async receiveMessageAction({commit, state}, message) {
        //check if the conversation is active, if not drop into inbox

        if (
            message.conversationId &&
            state.activeConversationId === message.conversationId
        ) {
            commit("ADD_MESSAGE", message);
        } else {
            commit("ADD_MESSAGE_TO_INBOX", message);

        }
    },

    async updateMessageAction({commit}, message) {
        console.log("Updating message: ", message);
        commit("UPDATE_MESSAGE", message);
    },

    async rescanDomainAction({dispatch, commit}, domainName) {
        let payload = {
            data: {
                text: "Sending to scan: " + domainName,
            },
            color: "green",
            timeout: 2000,
        };
        dispatch("snackbar/setSnackbar", payload, {root: true});

        const response = await backendApi.scanPDDomain(domainName);
        conversation.lead.domain = response.data;
        commit("UPDATE_CONVERSATION_DOMAIN", conversation);
        payload = {
            data: {
                text: "Scanned: " + JSON.stringify(conversation.lead.domain),
            },
            color: "blue",
            timeout: 2000,
        };
        dispatch("snackbar/setSnackbar", payload, {root: true});
    },

    async updateConversationAction({commit}, payload) {
        await conversationAPI.updateConversation(payload);
        commit("UPDATE_CONVERSATION_STATE_BY_ID", payload);

    },

    async updateByNameAction({commit}, payload) {
        commit("UPDATE_STAGE_BY_NAME", payload);
    },

    async hideConversationAction({commit}, payload) {
        conversationAPI.delete(payload);
        commit("DELETE_CONVERSATION", payload.id);
    },

    async sendMessageAction({commit, dispatch}, message) {
        try {
            const {data} = await messageAPI.send(message);
            console.log("Adding message: ", data);
            commit("ADD_MESSAGE", data);

        } catch (error) {
            console.log(error.response.data);

            let snack = {
                data: {
                    text: error.response.data.message
                },
                color: "red",
                timeout: 2000,
            };
            dispatch("snackbar/setSnackbar", snack, {root: true});
            throw error;
        }
    },

    async rephraseAction({commit}, payload) {
      const {data} = await messageAPI.rephrase(payload);
      return data;
    },

    placeCallAction({commit}, number) {
        callAPI.call(number);
    },


    async loadConversationsByDomainNameAction({commit, dispatch}, name) {
        const conversations = await conversationAPI.loadByDomainName(name);
        // const payload = {
        //     data: {
        //         message: "Loaded conversations: " + conversations.length,
        //     },
        //     color: "blue",
        //     timeout: 2000,
        // };
        // dispatch("snackbar/setSnackbar", payload, {root: true});
        console.log("Loaded conversations: ", conversations);
        conversations.map(c => commit("ADD_CONVERSATION", c));
    },

    async loadConversationsByLeadAction({commit, dispatch}, payload) {

        try {
            let data = await conversationAPI.postByLead(payload);
            commit("ADD_CONVERSATION", data);
            return Promise.resolve(data);

        } catch (error) {
            console.log("Was not able to make a new conversation with a lead ", payload, error)
            let snack = {
                data: {
                    text: error.message
                },
                color: "red",
                timeout: 2000,
            };
            dispatch("snackbar/setSnackbar", snack, {root: true});

            return Promise.resolve(error);
        }

    },

    async loadConversationByApolloAction({commit, dispatch}, payload) {

        try {
            let data = await conversationAPI.postByApollo(payload);
            commit("ADD_CONVERSATION", data);
            return Promise.resolve(data);

        } catch (error) {
            console.log("Was not able to make a new conversation with a lead ", payload, error)
            let snack = {
                data: {
                    text: error.message
                },
                color: "red",
                timeout: 2000,
            };
            dispatch("snackbar/setSnackbar", snack, {root: true});

            return Promise.resolve(error);
        }

    },

    async loadConversationsByPhoneNumberAction({commit}, phone) {
        let data = await conversationAPI.loadByPhoneNumber(phone);
        // .catch(e => {
        //     commit("SET_LOADING", false);
        //     if ('Unauthorized' === e.response.data.error) {
        //         dispatch("logout", null, {root: true});
        //     }
        // });
        commit("ADD_CONVERSATION", data);
        return Promise.resolve();
    },

    async loadConversationByIdAction({commit}, id) {
        let data = await conversationAPI.loadById(id);
        // .catch(e => {
        //     commit("SET_LOADING", false);
        //     if ('Unauthorized' === e.response.data.error) {
        //         dispatch("logout", null, {root: true});
        //     }
        // });
        commit("ADD_CONVERSATION", data);
        return Promise.resolve();
    },


    async loadAllConversations({commit, state}) {
        if (!Array.isArray(state.conversations) || !state.conversations.length) {
            commit("SET_LOADING", true);
            const data = await conversationAPI.loadAllActive();
            commit("SET_CONVERSATIONS", data);
            commit("SET_LOADING", false);
        }
    },

    async clearSearch({commit}) {
        commit("CLEAR_SEARCH");
    },
};

const getters = {
    getNotAnswered(state) {
        return state.notAnswered;
    },


    getByDomainId: (state) => (id) => {
        let convos = state.conversations.filter(
            (c) => c.domain && c.domain.id === id
        );
        return convos;
    },
    getConversationByPhone: (state) => (phone) => {
        const convo = state.conversations.find(
            (c) => c.phone && c.phone == phone
        );
        if (convo) {
            console.log("Found a conversation by phone:", phone, convo)

        }
        return convo;
    },

    getConversationByEmail: (state) => (email) => {
        const convo = state.conversations.find(
            (c) => c.lead.email && c.lead.email === email
        );
        if (convo) {
            console.log("Found a conversation by email:", email, convo)

        }
        return convo;
    },

    getConversationByLeadId: (state) => (leadId) => {
        const convo = state.conversations.find(
            (c) => c.leadId === leadId
        );
        if (convo) {
            console.log("Found a conversation by lead id:", leadId, convo)
        }
        return convo;
    },


    getConversationsByDomainName: (state) => (name) => {
        return state.conversations.filter(
            (c) =>
                c.domain && c.domain.toLowerCase() === name.toLowerCase()
        ).sort((a, b) =>
            new moment(b.lastUpdated).valueOf() -
            new moment(a.lastUpdated).valueOf()
        );
    },


    getConversationsBySiblingName: (state) => (name) => {
        console.log("Looking for conversations with ", name);

        let result = state.conversations.filter(
            (c) =>
                c.sibling &&
                c.sibling.toLowerCase() === name.toLowerCase()
        ).sort((a, b) =>
            new moment(b.lastUpdated).valueOf() -
            new moment(a.lastUpdated).valueOf()
        );
        console.log("Found conversations with ", name, result);

        return result;
    },

    getConversationByMessageId: (state) => (id) => {

        let convo = state.conversations.find(
            (c) =>
                c.messages.find(m => m.id === id)
        );
        return convo;
    },

    getConversationById: (state) => (id) => {
        let convo = state.conversations.find((c) => c.id == id);
        console.log("Loaded conversation data", convo)

        return convo;
    },

    getConversations: (state) => {
        return state.conversations ? [] : state.conversations;
    },

    getSortedConversations: (state) => {

        let sorted = state.conversations.sort(
            (a, b) =>
                new moment(b.lastUpdated).valueOf() -
                new moment(a.lastUpdated).valueOf()
        );
        // .sort((a, b) => (a.notAnswered === b.notAnswered ? 0 : b.notAnswered ? -1 : 1));
        return sorted;
    },

};

const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced,
};
